import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

console.log('PodDisplay component is being initialized');

// Set the app element for accessibility
Modal.setAppElement('#root');

// Use relative URL for API calls
const API_URL = '/api';
console.log('API_URL is set to:', API_URL);

const PodDisplay = () => {
  console.log('PodDisplay component function is being rendered');

  const [podInfo, setPodInfo] = useState([]);
  const [selectedPodLogs, setSelectedPodLogs] = useState('');
  const [selectedPodName, setSelectedPodName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('useEffect hook is triggered');

    const fetchPodInfo = async () => {
      console.log('fetchPodInfo function is called');
      try {
        setLoading(true);
        console.log('Setting loading state to true');
        
        const fullUrl = `${API_URL}/pods`;
        console.log('Preparing to make API call to:', fullUrl);
        console.log('Axios version:', axios.VERSION);
        console.log('Full request config:', {
          method: 'get',
          url: fullUrl,
          headers: { 'Content-Type': 'application/json' }
        });

        const response = await axios.get(fullUrl);
        
        console.log('API call successful');
        console.log('Response status:', response.status);
        console.log('Response headers:', response.headers);
        console.log('Response data:', response.data);

        setPodInfo(response.data);
        console.log('Pod info state updated');

        setError(null);
        console.log('Error state cleared');
      } catch (error) {
        console.error('Error occurred in fetchPodInfo:', error);
        if (error.response) {
          console.error('Error response status:', error.response.status);
          console.error('Error response data:', error.response.data);
          console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        
        setError(`Failed to fetch pod information: ${error.message}`);
        console.log('Error state updated');
      } finally {
        setLoading(false);
        console.log('Loading state set to false');
      }
    };

    fetchPodInfo();
  }, []);

  const fetchPodLogs = async (podName) => {
    console.log(`fetchPodLogs function called for pod: ${podName}`);
    try {
      setLoading(true);
      console.log('Setting loading state to true for log fetch');

      const fullUrl = `${API_URL}/logs/${podName}`;
      console.log('Preparing to make API call for logs:', fullUrl);
      const response = await axios.get(fullUrl);

      console.log('Log API call successful');
      console.log('Log response status:', response.status);
      console.log('Log response data:', response.data);

      setSelectedPodLogs(response.data.logs);
      setSelectedPodName(podName);
      setIsModalOpen(true);
      setError(null);

      console.log('States updated after successful log fetch');
    } catch (error) {
      console.error(`Error fetching logs for pod ${podName}:`, error);
      setError(`Failed to fetch logs for pod ${podName}: ${error.message}`);
      setSelectedPodLogs('');
    } finally {
      setLoading(false);
      console.log('Loading state set to false after log fetch');
    }
  };

  const closeModal = () => {
    console.log('closeModal function called');
    setIsModalOpen(false);
    setSelectedPodLogs('');
    setSelectedPodName('');
    console.log('Modal closed and related states reset');
  };

  console.log('Rendering PodDisplay component');
  console.log('Current podInfo state:', podInfo);
  console.log('Current error state:', error);
  console.log('Current loading state:', loading);

  return (
    <div className="pod-display">
      {loading && <div className="loading">Loading...</div>}
      {error && <div className="error-message">{error}</div>}
      <div className="pod-container">
        {podInfo.map((pod) => (
          <div key={pod.name} className="pod-icon" onClick={() => fetchPodLogs(pod.name)}>
            <img src="/k8s_pod.png" alt={`Pod: ${pod.app}`} />
            <span>{pod.app}</span>
          </div>
        ))}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Pod Logs"
        className="log-modal"
        overlayClassName="log-modal-overlay"
      >
        <h2>Logs for Pod: {selectedPodName}</h2>
        <pre>{selectedPodLogs}</pre>
        <button onClick={closeModal} className="close-modal-button">Close</button>
      </Modal>
    </div>
  );
};

export default PodDisplay;