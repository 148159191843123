import React, { useState } from 'react';
import PodDisplay from './components/PodDisplay';
import RedAlert from './components/RedAlert'; // Import the RedAlert component for real-time alerting
import './App.css';
import logo from './hypergame_logo_new.png';

function App() {
  const [refreshKey, setRefreshKey] = useState(0);

  const handleRefresh = () => {
    setRefreshKey(oldKey => oldKey + 1);
  };

  return (
    <div className="app-container">
      <img src={logo} alt="Company Logo" className="company-logo" />
      <h1 className="dashboard-title">A-TIER RENDITION</h1>
      <div className="content-container">
        <PodDisplay key={refreshKey} />
        <div className="cloud-sql-container">
          <div className="cloud-sql-icon">
            <img src="/cloud-sql-icon.png" alt="Cloud SQL" />
            <span>bitcoin_db</span>
          </div>
        </div>
        <button onClick={handleRefresh} className="refresh-button">Refresh Pods</button>
      </div>
      
      {/* Include the RedAlert component to handle real-time alerts */}
      <RedAlert />

      <footer className="footer">© 2024 Hypergame Inc.</footer>
    </div>
  );
}

export default App;
