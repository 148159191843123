import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './AlertScreen.css';

const RedAlert = () => {
  const [alert, setAlert] = useState(false);
  const [playSound, setPlaySound] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTriggered, setAlertTriggered] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [audio] = useState(new Audio('/red_alert.mp3'));

  // Initialize session
  useEffect(() => {
    const initSession = async () => {
      try {
        const response = await axios.post('/api/alert/init-session');
        const newSessionId = response.data.sessionId;
        setSessionId(newSessionId);
        console.log('Session initialized:', newSessionId);
        
        // Immediately check if there are any active alerts
        const alertCheck = await axios.post('/api/alert', {
          sessionId: newSessionId
        });
        
        console.log('Initial alert check:', alertCheck.data);
        
        if (alertCheck.data.message === 'ALERT_TRUE' || 
            alertCheck.data.status === 'Alert triggered' ||
            alertCheck.data.status === 'Alert successfully triggered') {
          handleAlertTrigger();
        }
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    };

    initSession();
  }, []);

  // Handle alert trigger
  const handleAlertTrigger = useCallback(() => {
    console.log('Triggering alert display and sound');
    setAlert(true);
    setPlaySound(true);
    setAlertMessage('Red Alert::ShieldsUp::SSRF Detected in Bitcoin-create-user Pod');
    setAlertTriggered(true);
  }, []);

  // Audio setup
  useEffect(() => {
    audio.volume = 0.5;
    
    const handleEnded = () => {
      setPlaySound(false);
    };
    
    audio.addEventListener('ended', handleEnded);
    return () => {
      audio.removeEventListener('ended', handleEnded);
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  // Handle sound playing
  useEffect(() => {
    let timeoutId;

    if (playSound) {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Audio playback error:', error);
          setPlaySound(false);
        });

        timeoutId = setTimeout(() => {
          audio.pause();
          audio.currentTime = 0;
          setPlaySound(false);
        }, 5000);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      audio.pause();
      audio.currentTime = 0;
    };
  }, [playSound, audio]);

  // Check alert status
  const checkAlertStatus = useCallback(async () => {
    if (!sessionId) return;

    try {
      const response = await axios.post('/api/alert', {
        sessionId: sessionId
      });

      console.log('Alert status response:', response.data);

      // Check for any alert trigger condition
      if (response.data.message === 'ALERT_TRUE' || 
          response.data.status === 'Alert triggered' ||
          response.data.status === 'Alert successfully triggered') {
        if (!alertTriggered) {
          console.log('Alert condition detected!');
          handleAlertTrigger();
        }
      }
    } catch (error) {
      console.error('Error checking alert status:', error);
    }
  }, [sessionId, alertTriggered, handleAlertTrigger]);

  // Poll for alert status
  useEffect(() => {
    if (!sessionId) return;

    console.log('Setting up alert polling with session:', sessionId);
    const intervalId = setInterval(checkAlertStatus, 2000); // Polling more frequently
    checkAlertStatus(); // Initial check

    return () => {
      console.log('Cleaning up alert polling');
      clearInterval(intervalId);
    };
  }, [sessionId, checkAlertStatus]);

  return (
    <div className="alert-container">
      {alert && (
        <>
          <div className="alert-overlay alert-active" aria-hidden="true" />
          <div className="alert-message-container">
            {alertMessage && (
              <div className="alert-message" role="alert">
                {alertMessage}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RedAlert;